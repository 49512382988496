import { useEffect, useRef } from 'react';

import type { FormState } from 'utils/forms';
import { toast } from 'components/ui/sonner';

const useFormToastMessage = (formState: FormState) => {
  const prevTimestamp = useRef(formState.timestamp);

  const showToast =
    formState.message && formState.timestamp !== prevTimestamp.current;

  useEffect(() => {
    if (showToast) {
      if (formState.message) {
        if (formState.status === 'ERROR') {
          toast.error(formState.message);
        } else {
          toast.success(formState.message);
        }
      }

      prevTimestamp.current = formState.timestamp;
    }
  }, [formState, showToast]);
};

export { useFormToastMessage };
