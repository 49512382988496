import type { Database } from 'schema.gen';
import type { SupabaseClient } from '@supabase/supabase-js';

export type InventoryItem = {
  id: string;
  image: string;
  name: string;
  value: number;
  display_properties?: {
    name: string;
    value: string;
    detail_level: 'BASIC' | 'ADVANCED';
  }[];
  mass: number;
  mass_unit: Database['public']['Enums']['mass_unit'];
  is_vaulted: boolean;
  external_id: string;
  status: Database['public']['Enums']['inventory_item_status'];
  created_at: string;
};
export default async function getUserInventoryItems(
  supabase: SupabaseClient<Database>,
  { userId }: { userId: string },
) {
  const { data: items } = await supabase
    .from('inventory_items')
    .select('*')
    .eq('owner', userId)
    .eq('status', 'in_inventory')
    .returns<InventoryItem[]>();

  return items ?? [];
}
