'use client';

import { Checkbox } from 'components/ui/checkbox';
import { InventoryPageContext } from './inventory-page-context';
import { cn } from 'utils/shadcn';
import { useContext } from 'react';

export default function InventoryItemSelectCheckbox({
  itemId,
}: {
  itemId: string;
}) {
  const { selectedItems, onItemSelect, soldItemIds } =
    useContext(InventoryPageContext);

  const isSelected = selectedItems.some((item) => item.id === itemId);
  const isSold = soldItemIds.includes(itemId);

  if (isSold) {
    return null;
  }

  return (
    <div
      className={cn('relative cursor-pointer transition-transform', {
        'translate-x-[200%] group-hover:translate-x-0': !isSelected,
      })}
    >
      <div className="bg-frost inline-flex h-8 items-center justify-center gap-1 whitespace-nowrap rounded-sm px-3 text-sm font-medium transition-colors hover:bg-opacity-25 hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
        <Checkbox id={itemId} checked={isSelected} />
        <label
          htmlFor={itemId}
          className="hidden cursor-pointer select-none leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 md:block"
        >
          {isSelected ? 'Selected' : 'Select'}
        </label>
      </div>
      <div
        className="absolute left-0 top-0 h-full w-full cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          onItemSelect(itemId);
        }}
      />
    </div>
  );
}
