import React from 'react';

export default function InfoIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.514 16.7557C13.1779 17.6485 11.607 18.125 10 18.125C7.84638 18.1209 5.78216 17.2635 4.25932 15.7407C2.73648 14.2178 1.87913 12.1536 1.875 10C1.875 8.39303 2.35152 6.82214 3.24431 5.48599C4.13709 4.14984 5.40604 3.10844 6.89069 2.49348C8.37534 1.87851 10.009 1.71761 11.5851 2.03112C13.1612 2.34462 14.6089 3.11845 15.7452 4.25475C16.8815 5.39106 17.6554 6.83879 17.9689 8.41489C18.2824 9.99098 18.1215 11.6247 17.5065 13.1093C16.8916 14.594 15.8502 15.8629 14.514 16.7557ZM11.4286 14.2857C11.4286 15.0747 10.789 15.7143 10 15.7143C9.21102 15.7143 8.57143 15.0747 8.57143 14.2857L8.57143 10C8.57143 9.21102 9.21102 8.57143 10 8.57143C10.789 8.57143 11.4286 9.21102 11.4286 10L11.4286 14.2857ZM8.57143 5.71428C8.57143 4.92531 9.21102 4.28571 10 4.28571C10.789 4.28571 11.4286 4.92531 11.4286 5.71428C11.4286 6.50326 10.789 7.14286 10 7.14286C9.21102 7.14286 8.57143 6.50326 8.57143 5.71428Z"
        fill="currentColor"
      />
    </svg>
  );
}
