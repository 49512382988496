'use client';

import { createContext, useContext, type ReactNode } from 'react';
import type { InventoryItem } from 'queries/get-user-inventory-items';
import Observe from 'components/events/observe';
import { useQuery } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import getUserInventoryItems from 'queries/get-user-inventory-items';
import { useDebouncedCallback } from '@mantine/hooks';

export const InventoryContext = createContext<{
  items: InventoryItem[];
  count: number;
  isLoading: boolean;
}>({
  items: [],
  count: 0,
  isLoading: false,
});

export default function InventoryProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { data, isLoading } = useQuery({
    queryKey: ['inventory_items'],
    staleTime: Infinity,
    queryFn: async () => {
      const createClient = await import('utils/supabase/client').then(
        (mod) => mod.createClient,
      );
      const client = createClient();
      const session = await client.auth.getSession();
      const userId = session.data.session?.user.id;
      if (!userId) {
        return [];
      }
      return getUserInventoryItems(client, { userId });
    },
  });

  const items = data ?? [];

  const queryClient = useQueryClient();

  const invalidate = useDebouncedCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['inventory_items'] });
  }, 1000);

  return (
    <InventoryContext.Provider
      value={{
        items: items,
        count: items.length,
        isLoading,
      }}
    >
      {children}
      <Observe onInventoryChanged={invalidate} />
    </InventoryContext.Provider>
  );
}

export const useInventoryContext = () => {
  return useContext(InventoryContext);
};

// 'use client';

// import { createContext, useCallback, useContext, type ReactNode } from 'react';
// import type { InventoryItem } from './inventory/inventory-page-context';
// import { getInventoryItems } from './inventory/queries';
// import { useQuery, useQueryClient } from '@tanstack/react-query';

// export const InventoryContext = createContext<{
//   items: InventoryItem[];
//   count: number;
//   isFetching: boolean;
//   isLoading: boolean;
//   invalidate: () => Promise<void>;
// }>({
//   items: [],
//   count: 0,
//   isFetching: false,
//   isLoading: false,
//   invalidate: async () => {},
// });

// export default function InventoryProvider({
//   children,
// }: {
//   children: ReactNode;
// }) {
//   const { data, isFetching, isLoading } = useQuery({
//     queryKey: ['inventory_items'],
//     staleTime: Infinity,
//     queryFn: async () => {
//       const createClient = await import('utils/supabase/client').then(
//         (mod) => mod.createClient,
//       );
//       const client = createClient();
//       const session = await client.auth.getSession();
//       const userId = session.data.session?.user.id;
//       if (!userId) {
//         return { items: [], count: 0 };
//       }
//       return getInventoryItems(client, { userId });
//     },
//   });

//   const { items = [], count = 0 } = data ?? {};

//   const queryClient = useQueryClient();

//   const invalidate = useCallback(async () => {
//     await queryClient.invalidateQueries({ queryKey: ['inventory_items'] });
//   }, [queryClient]);

//   return (
//     <InventoryContext.Provider
//       value={{ items, count, isFetching, isLoading, invalidate }}
//     >
//       {children}
//     </InventoryContext.Provider>
//   );
// }

// export const useInventoryContext = () => {
//   return useContext(InventoryContext);
// };
