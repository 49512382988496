export default function ArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={9}
      viewBox="0 0 14 9"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14 2 7 9 0 2h2l5 5 5-5h2Z"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="m5 2 2-2 2 2-2 2-2-2Z" />
    </svg>
  );
}
