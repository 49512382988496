'use client';

import InventoryItemCard from './inventory-item-card';
import Typography from 'components/ui/typography';
import { cn } from 'utils/shadcn';
import { useInventoryPageContext } from './inventory-page-context';

export default function InfiniteInventoryGrid() {
  const { items } = useInventoryPageContext();

  return (
    <div
      className={cn(
        items.length > 0 &&
          'grid grid-cols-[repeat(auto-fill,minmax(150px,1fr))] gap-2',
        items.length === 0 && 'flex items-center justify-center',
      )}
    >
      {items.map((item) => (
        <InventoryItemCard
          key={item.id}
          itemId={item.id}
          name={item.name}
          image={item.image}
          value={item.value}
          displayProperties={item.display_properties}
        />
      ))}
      {items.length === 0 && (
        <div className="flex flex-col items-center justify-center gap-4">
          <Typography variant="p" affects="muted">
            You have no items in your inventory.
          </Typography>
        </div>
      )}
    </div>
  );
}
