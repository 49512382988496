'use client';

import { Button } from 'components/ui/button';
import InfoIcon from 'icons/info';
import dynamic from 'next/dynamic';
import { useState } from 'react';

const ItemDetailDialog = dynamic(() => import('components/item-detail-dialog'));

export default function InventoryItemDetailButton({
  name,
  image,
  displayProperties,
}: {
  name: string;
  image: string;
  displayProperties: {
    name: string;
    value: string;
    detail_level: 'BASIC' | 'ADVANCED';
  }[];
}) {
  const [open, setOpen] = useState(false);

  return (
    <div className="relative">
      <ItemDetailDialog
        open={open}
        onOpenChange={(open) => setOpen(open)}
        name={name}
        image={image}
        displayProperties={displayProperties}
      />
      <Button variant="frost" onClick={() => setOpen(true)} size="icon">
        <InfoIcon />
      </Button>
    </div>
  );
}
