'use client';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';

import { useInventoryPageContext } from './inventory-page-context';

export default function OrderBy({ className }: { className?: string }) {
  const {
    order: { column, direction },
    setOrder,
  } = useInventoryPageContext();

  return (
    <Select
      value={`${column}-${direction}`}
      onValueChange={(value) => {
        const [column, direction] = value.split('-');
        setOrder({
          column: column as 'created_at' | 'value',
          direction: direction as 'asc' | 'desc',
        });
      }}
    >
      <SelectTrigger className={className}>
        <SelectValue placeholder="Order by" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="created_at-desc" className="flex items-center gap-2">
          Newest First
        </SelectItem>
        <SelectItem value="created_at-asc" className="flex items-center gap-2">
          Oldest First
        </SelectItem>
        <SelectItem value="value-asc" className="flex items-center gap-2">
          Lowest Value First
        </SelectItem>
        <SelectItem value="value-desc" className="flex items-center gap-2">
          Highest Value First
        </SelectItem>
      </SelectContent>
    </Select>
  );
}
