'use client';

import Coins from 'components/coins';
import Image from 'next/image';
import InventoryItemDetailButton from './inventory-item-detail-button';
import InventoryItemSelectCheckbox from './inventory-item-select-checkbox';
import { InventoryPageContext } from './inventory-page-context';
import Typography from 'components/ui/typography';
import { useContext } from 'react';

export default function InventoryItemCard({
  itemId,
  name,
  image,
  value,
  displayProperties,
}: {
  itemId: string;
  name: string;
  image: string;
  value: number;
  displayProperties?: {
    name: string;
    value: string;
    detail_level: 'BASIC' | 'ADVANCED';
  }[];
}) {
  const { soldItemIds, onItemSelect, selectedItems } =
    useContext(InventoryPageContext);

  const isSelected = selectedItems.some((item) => item.id === itemId);
  const isSold = soldItemIds.includes(itemId);

  return (
    <div
      className={`bg-frost group relative flex cursor-pointer flex-col space-y-2 overflow-hidden rounded-lg border-4 border-transparent text-center ${isSold ? 'saturate-0' : ''}`}
      onClick={() => {
        onItemSelect(itemId);
      }}
    >
      <div
        className={`absolute bottom-0 left-1/2 aspect-square w-full rounded-[400%] bg-gold blur-4xl transition-all -translate-x-1/2 ${isSelected ? 'opacity-100 translate-y-1/2' : 'opacity-0 translate-y-full'}`}
      />

      <div className="relative aspect-square w-full">
        <Image
          src={image}
          alt={name}
          sizes="(max-width: 767px) 100vw, 20vw"
          fill
          className="relative mx-auto w-auto select-none object-contain p-2 transition-transform scale-90"
          draggable={false}
        />
        {isSold && (
          <Typography
            variant="p"
            affects="large"
            className="absolute left-1/2 top-1/2 z-20 animate-jump-in border-4 border-black bg-black text-4xl tracking-widest -translate-x-1/2 -translate-y-1/2 -rotate-45"
          >
            SOLD
          </Typography>
        )}
      </div>
      <Typography
        variant="p"
        affects="small"
        className="z-10 line-clamp-3 grow"
      >
        {name}
      </Typography>
      <span className="w-fit self-center rounded-tl-lg rounded-tr-lg bg-white bg-opacity-5 p-2 backdrop-blur-sm">
        <Coins value={value} className="z-10" />
      </span>

      <span className="absolute flex w-full items-center justify-between px-2">
        <span
          className="relative transition-transform -translate-x-[200%] group-hover:translate-x-0"
          onClick={(e) => e.stopPropagation()}
        >
          {displayProperties && (
            <InventoryItemDetailButton
              name={name}
              image={image}
              displayProperties={displayProperties}
            />
          )}
        </span>

        <InventoryItemSelectCheckbox itemId={itemId} />
      </span>
    </div>
  );
}
