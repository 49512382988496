export default function DiamondIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 5L5 0.5L10 5L5 10L0 5Z" fill="currentColor" />
    </svg>
  );
}
