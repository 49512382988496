import { ZodError } from 'zod';
import { isRedirectError } from 'next/dist/client/components/redirect';
import { PullboxError, type ERROR_CODES } from 'lib/errors';

export type FormState = {
  status: 'UNSET' | 'SUCCESS' | 'ERROR';
  message?: string;
  body?: unknown;
  fieldErrors: Record<string, string[] | undefined>;
  timestamp: number;
  errorCode?: (typeof ERROR_CODES)[keyof typeof ERROR_CODES];
};

export const EMPTY_FORM_STATE: FormState = {
  status: 'UNSET' as const,
  message: '',
  fieldErrors: {},
  timestamp: Date.now(),
};

export const fromErrorToFormState = (error: unknown): FormState => {
  // NextJS uses an error to trigger redirects, so we need to handle
  // this as a special case
  if (isRedirectError(error)) {
    throw error;
  }

  if (error instanceof ZodError) {
    return {
      status: 'ERROR' as const,
      message: '',
      fieldErrors: error.flatten().fieldErrors,
      timestamp: Date.now(),
    };
  } else if (error instanceof Error) {
    return {
      status: 'ERROR' as const,
      message: error.message,
      fieldErrors: {},
      timestamp: Date.now(),
    };
  } else if (error instanceof PullboxError) {
    return {
      status: 'ERROR' as const,
      message: error.message,
      fieldErrors: {},
      timestamp: Date.now(),
      errorCode: error.code,
    };
  } else {
    return {
      status: 'ERROR' as const,
      message: 'An unknown error occurred',
      fieldErrors: {},
      timestamp: Date.now(),
    };
  }
};

export const toFormState = (
  status: FormState['status'],
  message?: string,
  body?: unknown,
): FormState => {
  return {
    status,
    message,
    body,
    fieldErrors: {},
    timestamp: Date.now(),
  };
};
