'use client';

import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useListState } from '@mantine/hooks';
import { useInventoryContext } from 'app/(main)/inventory-context';
import type { InventoryItem } from 'queries/get-user-inventory-items';

export const InventoryPageContext = createContext<{
  items: InventoryItem[];
  selectedItems: InventoryItem[];
  onItemSelect: (itemId: string) => void;
  selectAll: () => void;
  deselectAll: () => void;
  totalItemCount: number;
  selectedItemValue: number;
  totalItemValue: number;
  allItemsSelected: boolean;
  noItemsSelected: boolean;
  clearSelection: () => void;
  soldItemIds: string[];
  appendSoldItemIds: (...ids: string[]) => void;
  filterSoldItemIds: (fn: (id: string) => boolean) => void;
  order: {
    column: 'created_at' | 'value';
    direction: 'asc' | 'desc';
  };
  setOrder: (order: {
    column: 'created_at' | 'value';
    direction: 'asc' | 'desc';
  }) => void;
}>({
  items: [],
  onItemSelect: () => {},
  selectAll: () => {},
  deselectAll: () => {},
  totalItemCount: 0,
  selectedItems: [],
  selectedItemValue: 0,
  totalItemValue: 0,
  allItemsSelected: false,
  noItemsSelected: true,
  clearSelection: () => {},
  soldItemIds: [],
  appendSoldItemIds: () => {},
  filterSoldItemIds: () => {},
  order: {
    column: 'created_at',
    direction: 'desc',
  },
  setOrder: () => {},
});

export default function InventoryPageProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { items } = useInventoryContext();

  const [selectedItems, { append, filter, setState }] =
    useListState<InventoryItem>([]);
  const [
    soldItemIds,
    { append: appendSoldItemIds, filter: filterSoldItemIds },
  ] = useListState<string>([]);

  const [order, setOrder] = useState<{
    column: 'created_at' | 'value';
    direction: 'asc' | 'desc';
  }>({
    column: 'created_at',
    direction: 'desc',
  });

  const clearSelection = useCallback(() => {
    setState([]);
  }, [setState]);

  const totalItemValue = items.reduce((acc, item) => acc + item.value, 0);
  const allItemsSelected = selectedItems.length === items.length;
  const noItemsSelected = selectedItems.length === 0;

  const selectedItemValue = noItemsSelected
    ? totalItemValue
    : selectedItems.reduce((acc, selectedItem) => {
        const item = items?.find((item) => item.id === selectedItem.id);
        return acc + (item?.value ?? 0);
      }, 0);

  const orderedItems = useMemo(() => {
    return [...(items ?? [])].sort((a, b) => {
      if (order.column === 'value') {
        return order.direction === 'asc'
          ? a.value - b.value
          : b.value - a.value;
      } else {
        return order.direction === 'asc'
          ? a.created_at.localeCompare(b.created_at)
          : b.created_at.localeCompare(a.created_at);
      }
    });
  }, [items, order]);

  return (
    <InventoryPageContext.Provider
      value={{
        items: orderedItems,
        onItemSelect: (itemId: string) => {
          if (selectedItems.some((item) => item.id === itemId)) {
            filter((item) => item.id !== itemId);
          } else {
            if (items) {
              append(items.find((item) => item.id === itemId)!);
            }
          }
        },
        selectAll: () => {
          setState(items ?? []);
        },
        deselectAll: () => {
          setState([]);
        },
        totalItemCount: items.length,
        selectedItems,
        selectedItemValue,
        totalItemValue,
        allItemsSelected,
        noItemsSelected,
        clearSelection,
        soldItemIds,
        appendSoldItemIds,
        filterSoldItemIds,
        order,
        setOrder,
      }}
    >
      {children}
    </InventoryPageContext.Provider>
  );
}

export const useInventoryPageContext = () => {
  return useContext(InventoryPageContext);
};
