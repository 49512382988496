'use client';

import AnimatedNumbers from 'components/animated-numbers';
import { Button, type ButtonProps } from 'components/ui/button';
import { InventoryPageContext } from './inventory-page-context';
import formatCoins from 'utils/format/coins';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { sellInventoryItems, undoSellInventoryItems } from 'actions/inventory';
import { EMPTY_FORM_STATE, type FormState } from 'utils/forms';
import { useFormState, useFormStatus } from 'react-dom';
import { useFormToastMessage } from 'hooks/use-form-toast-message';
import { toast } from 'components/ui/sonner';
import pluralize from 'pluralize';
import Coins from 'components/coins';
import { cn } from 'utils/shadcn';
import { CheckIcon } from 'lucide-react';
import { useEvents } from 'components/events/context';
import type { InventoryItem } from 'queries/get-user-inventory-items';

export default function SellButton({ onSuccess }: { onSuccess?: () => void }) {
  const {
    selectedItems,
    selectedItemValue,
    allItemsSelected,
    noItemsSelected,
    totalItemValue,
    totalItemCount,
    clearSelection,
    appendSoldItemIds,
    filterSoldItemIds,
    items,
  } = useContext(InventoryPageContext);

  const [state, setState] = useState<'default' | 'confirming'>('default');
  const { emitter } = useEvents();
  const [formState, formAction] = useFormState<FormState, FormData>(
    sellInventoryItems,
    EMPTY_FORM_STATE,
  );
  const prevTimestamp = useRef(formState.timestamp);
  const prevSelectedItems = useRef<InventoryItem[]>(selectedItems);

  if (prevSelectedItems.current !== selectedItems) {
    prevSelectedItems.current = selectedItems;

    setState('default');
  }

  useFormToastMessage(formState);

  const undoSell = useCallback(
    async (batchId: string) => {
      const response = await undoSellInventoryItems(batchId);

      if (response.status === 'SUCCESS') {
        const body = response.body as { ids: string[] };
        filterSoldItemIds((id) => !body.ids.includes(id));
        emitter.emit('items-sold-undo', { ids: body.ids });
      } else {
        toast.error('Undo failed');
      }
    },
    [filterSoldItemIds, emitter],
  );

  useEffect(() => {
    if (
      prevTimestamp.current !== formState.timestamp &&
      formState.status === 'SUCCESS'
    ) {
      prevTimestamp.current = formState.timestamp;
      const body = formState.body as {
        batchId: string;
        ids: string[];
        value: number;
      };

      setState('default');
      emitter.emit('items-sold', { ids: body.ids });

      appendSoldItemIds(...body.ids);
      clearSelection();

      toast.success(
        `Sold ${body.ids.length} ${pluralize('item', body.ids.length)} for ${formatCoins(body.value)} coins!`,
        {
          action: {
            label: 'Undo',
            onClick: () => {
              void undoSell(body.batchId);
            },
          },
          duration: 15_000,
          closeButton: true,
        },
      );
      onSuccess?.();
    }
  }, [
    appendSoldItemIds,
    clearSelection,
    formState.status,
    formState.timestamp,
    onSuccess,
    selectedItems,
    selectedItemValue,
    totalItemValue,
    undoSell,
    formState.body,
    emitter,
  ]);

  return (
    <form action={formAction} className="inline-block w-full md:w-fit">
      {!noItemsSelected &&
        selectedItems.map((item) => (
          <input key={item.id} type="hidden" name="ids" value={item.id} />
        ))}
      {noItemsSelected &&
        items.map((item) => (
          <input key={item.id} type="hidden" name="ids" value={item.id} />
        ))}
      <SubmitButton
        variant="frost"
        disabled={totalItemCount === 0}
        className={cn('relative w-full overflow-hidden md:w-fit', {
          'bg-success': state === 'confirming',
        })}
        onClick={(e) => {
          if (state === 'default') {
            setState('confirming');
            e.preventDefault();
          }
        }}
      >
        {state === 'default' && (
          <span className="flex items-center gap-1">
            Sell{' '}
            {allItemsSelected || noItemsSelected ? (
              'all'
            ) : (
              <AnimatedNumbers value={selectedItems.length} />
            )}{' '}
            for
            <Coins
              value={
                allItemsSelected || noItemsSelected
                  ? totalItemValue
                  : selectedItemValue
              }
              animated
            />
          </span>
        )}
        {state === 'confirming' && (
          <span className="flex animate-fade-up items-center gap-1 animate-duration-200">
            <CheckIcon className="size-4" />
            Confirm
          </span>
        )}
      </SubmitButton>
    </form>
  );
}

function SubmitButton(props: ButtonProps) {
  const { pending } = useFormStatus();

  return (
    <Button
      {...props}
      type="submit"
      isProcessing={pending}
      disabled={pending || props.disabled}
    >
      {props.children}
    </Button>
  );
}
