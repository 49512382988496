'use client';

import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { cn } from 'utils/shadcn';
import { useInventoryPageContext } from './inventory-page-context';

export default function SelectAll({ className }: { className?: string }) {
  const { allItemsSelected, selectAll, deselectAll, totalItemCount } =
    useInventoryPageContext();

  const isChecked = allItemsSelected && totalItemCount > 0;

  return (
    <Button
      onClick={isChecked ? deselectAll : selectAll}
      variant="frost"
      className={cn('w-min', className)}
      disabled={totalItemCount === 0}
      asChild
    >
      <div className="cursor-pointer select-none">
        <Checkbox checked={isChecked} className="mr-2" />
        {isChecked ? 'Deselect All' : 'Select All'}
      </div>
    </Button>
  );
}
